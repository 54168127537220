<template>
  <div v-if="uid == '8588314'" class="cntBox">
    <p class="title">测试</p>
    <p class="detail">{{ detailText }}</p>
    <p class="detail">{{ wxsdkRes }}</p>
    <p class="detail">{{ uid }}</p>
  </div>
  <div v-else class="cntBox">

  </div>
</template>

<script>
import Cookie from 'js-cookie'
import wxsdk from 'weixin-js-sdk'

export default {
  data() {
    return {
      detailText: '',
      uid: Cookie.get('uid') || '',
      wxsdkRes: ''
    }
  },
  created() {
    // 测试ua，判断各个环境
    var ua = window.navigator.userAgent.toLowerCase()
    this.detailText = ua

    // 检测小程序登录
    this.checkMPLogin()
  },
  methods: {
    // 检测小程序登录
    checkMPLogin() {
      const that = this
      var ua = window.navigator.userAgent.toLowerCase()
      // 该js 判断是否是在微信、微信小程序里，区分更准确
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        // 微信环境下
        console.log('微信环境下')
        wxsdk.miniProgram.getEnv(function(res) {
          that.wxsdkRes = res
        })
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.cntBox {
  .title {
    padding: 20px;
    font-size: 32px;
    text-align: center;
  }
  .detail {
    padding: 20px;
    font-size: 22px;
    text-align: left;
  }
}

</style>
